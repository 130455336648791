import Chart from "chart.js/auto";
//import { Tooltip } from "bootstrap";

let chartInstances = {};
let cachedLoginStatus = null;

$(() => {
    $(".fetch-etsy-keywords").on("submit", function (e) {
        e.preventDefault();

        var keyword = $(this).find("input[name=keyword]").val();
        var platform = $(this).find("input[name=platform]").val();
        var button = $(this).find("button[id=send-btn]");

        var input = document.getElementById("generated_tags");

        if (keyword.length == 0) {
            button.html("Please input a valid keyword");
            return;
        }

        button.html("Loading..");
        button.attr("disabled", true);
        input.innerHTML = "";

        $(".content-area").hide();

        checkLoginStatus().then((loggedIn) => {
            const isLoggedIn = loggedIn;

            document
                .querySelectorAll(".chart-tooltip")
                .forEach((el) => el.remove());

            $.ajax({
                url: "/api/v1/tags",
                type: "POST",
                data: {
                    keyword: keyword.toLowerCase(),
                    platform: platform,
                },
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (data) {
                    Object.values(chartInstances).forEach((chart) =>
                        chart.destroy()
                    );
                    chartInstances = {};

                    const keywords = JSON.parse(data);
                    const container = document.getElementById("generated_tags");

                    container.innerHTML = `
                    <table class="table table-hover text-start align-middle">
                        <thead>
                            <tr>
                                <th title="Keyword phrase related to your search">Keyword</th>
                                <th title="Estimated monthly search volume">Search Volume</th>
                                <th title="Change in search volume over the past year">Trend</th>
                                <th title="Search volume trend over time"></th>
                                <th title="Estimated cost-per-click in advertising">Average CPC</th>
                                <th title="Competition level for advertisers (Low, Medium, High)">Competition</th>
                            </tr>
                        </thead>
                        <tbody id="keyword-table-body"></tbody>
                    </table>`;

                    const tableBody =
                        document.getElementById("keyword-table-body");

                    const sortedKeywords = keywords
                        .filter((k) => typeof k.volume === "number")
                        .sort((a, b) => b.volume - a.volume);

                    sortedKeywords.forEach((keywordData, index) => {
                        const showRealData = isLoggedIn || index < 5;
                        const trendData = Array.isArray(keywordData.trend)
                            ? keywordData.trend.filter((t) => t?.value != null)
                            : [];

                        trendData.sort(
                            (a, b) =>
                                new Date(a.year, a.month - 1) -
                                new Date(b.year, b.month - 1)
                        );
                        const trendChange = showRealData
                            ? getTrendChange(trendData)
                            : 0;
                        const labels = formatLabels(trendData);
                        const values = trendData.map((t) => t.value);

                        const row = document.createElement("tr");

                        // Keyword
                        const keywordCell = document.createElement("td");

                        const inputWords = keyword.toLowerCase().split(" ");
                        const keywordWords = keywordData.keyword.split(" ");

                        const highlighted = keywordWords
                            .map((word) => {
                                return inputWords.includes(word.toLowerCase())
                                    ? word
                                    : `<strong>${word}</strong>`;
                            })
                            .join(" ");

                        keywordCell.innerHTML = highlighted;
                        row.appendChild(keywordCell);


                        // Volume
                        const volumeCell = document.createElement("td");
                        if (!showRealData) {
                            volumeCell.classList.add("blurred");
                        }
                        volumeCell.innerHTML = showRealData
                            ? keywordData.volume?.toLocaleString() || "N/A"
                            : `<span class="text-muted">12.345</span>`;
                        row.appendChild(volumeCell);

                        // Trend %
                        const trendPercentCell = document.createElement("td");
                        if (showRealData) {
                            const trendArrow = trendChange >= 0 ? "↑" : "↓";
                            const trendColor =
                                trendChange >= 0
                                    ? "text-success"
                                    : "text-danger";
                            const trendPercent =
                                Math.abs(trendChange).toFixed(0);
                            trendPercentCell.innerHTML = `<span class="${trendColor}">${trendArrow} ${trendPercent}%</span>`;
                        } else {
                            trendPercentCell.classList.add("blurred");
                            trendPercentCell.innerHTML = `<span class="text-muted">--%</span>`;
                        }
                        row.appendChild(trendPercentCell);

                        // Chart column
                        const chartId = `trendChart${index}`;
                        const miniChartId = `miniChart${index}`;
                        const chartCell = document.createElement("td");

                        if (showRealData) {
                            chartCell.innerHTML = `
                        <div class="mini-chart-trigger" data-chart-id="${chartId}" style="position: relative;">
                            <canvas id="${miniChartId}" width="60" height="25" style="display:block;"></canvas>
                        </div>`;

                            // Tooltip container
                            const tooltip = document.createElement("div");
                            tooltip.className =
                                "chart-tooltip bg-white p-3 rounded shadow";
                            tooltip.style.cssText = `
                                position: absolute;
                                display: none;
                                width: 500px;
                                height: 300px;
                                z-index: 9999;
                                border-radius: 8px;
                                box-shadow: 0 0 10px rgba(0,0,0,0.1);
                                background: white;
                            `;
                            tooltip.innerHTML = `<canvas id="${chartId}" width="470" height="240"></canvas>`;
                            document.body.appendChild(tooltip);

                            requestAnimationFrame(() => {
                                renderFullChart(
                                    chartId,
                                    labels,
                                    values,
                                    trendChange
                                );
                            });

                            const trigger = chartCell.querySelector(
                                ".mini-chart-trigger"
                            );
                            let tooltipTimeout;

                            const showTooltip = () => {
                                const rect = trigger.getBoundingClientRect();
                                tooltip.style.left = `${
                                    rect.left + window.scrollX - 220
                                }px`;
                                tooltip.style.top = `${
                                    rect.top + window.scrollY + 35
                                }px`;
                                tooltip.style.display = "block";
                            };

                            const hideTooltip = () => {
                                tooltipTimeout = setTimeout(() => {
                                    tooltip.style.display = "none";
                                }, 150);
                            };

                            const cancelHide = () =>
                                clearTimeout(tooltipTimeout);

                            trigger.addEventListener("mouseenter", showTooltip);
                            trigger.addEventListener("mouseleave", hideTooltip);
                            tooltip.addEventListener("mouseenter", cancelHide);
                            tooltip.addEventListener("mouseleave", hideTooltip);
                        } else {
                            chartCell.classList.add("blurred");
                        }
                        row.appendChild(chartCell);

                        // CPC
                        const cpcCell = document.createElement("td");
                        const cpcValue = parseFloat(
                            keywordData.cpc_value || 0
                        ).toFixed(2);
                        if (!showRealData) {
                            cpcCell.classList.add("blurred");
                        }
                        cpcCell.innerHTML = showRealData
                            ? `${keywordData.cpc_currency || "$"}${cpcValue}`
                            : `<span class="text-muted">$0.00</span>`;
                        row.appendChild(cpcCell);

                        // Competition
                        const compCell = document.createElement("td");
                        compCell.innerHTML = showRealData
                            ? renderCompetition(keywordData.competition)
                            : `<span class="text-muted">00</span>`;
                        if (!showRealData) {
                            compCell.classList.add("blurred");
                        }
                        row.appendChild(compCell);

                        tableBody.appendChild(row);

                        if (showRealData) {
                            renderMiniChart(miniChartId, labels, values);

                            requestAnimationFrame(() => {
                                renderFullChart(
                                    chartId,
                                    labels,
                                    values,
                                    trendChange
                                );
                            });
                        }

                        if (!isLoggedIn && index === 4) {
                            const ctaRow = document.createElement("tr");
                            const ctaCell = document.createElement("td");
                            ctaCell.colSpan = 6;
                            ctaCell.innerHTML = `
                            <div class="cta-wrapper-relative">
                                <div class="keyword-cta-absolute text-center">
                                <div class="mb-2"><strong>Unlock Accurate Search Data</strong> 🚀</div>
                                <p class="mb-3">
                                    Want real search volume, trend, charts, CPC, and competition insights?<br>
                                    Create your free MerchTitans account and reveal the data that matters.
                                </p>
                                <a href="/register" class="btn btn-warning px-4">Register with a Free Account</a>
                                </div>
                            </div>
                            `;

                            ctaRow.appendChild(ctaCell);
                            tableBody.appendChild(ctaRow);
                        }
                    });

                    $(".content-area").show();
                    $(".content-area .format-tags").show();
                    $(".content-area .sub-title").show();

                    // document
                    //     .querySelectorAll('[data-bs-toggle="tooltip"]')
                    //     .forEach((el) => {
                    //         new Tooltip(el);
                    //     });
                },

                error: function (xhr, status, error) {
                    $(".content-area").show();
                    $(".content-area .format-tags").hide();
                    $(".content-area .sub-title").hide();
                    input.innerHTML = xhr.responseText + ".";
                },
            }).always(function () {
                button.html('<i class="fa fa-search"></i> Search');
                button.attr("disabled", false);
            });
        });
    });
});

function checkLoginStatus() {
    if (cachedLoginStatus !== null) {
        return Promise.resolve(cachedLoginStatus);
    }

    return fetch("/api/v1/user", {
        credentials: "include",
        headers: {
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
        },
    })
        .then((res) => {
            cachedLoginStatus = res.status === 200;
            return cachedLoginStatus;
        })
        .catch(() => {
            cachedLoginStatus = false;
            return false;
        });
}

function getTrendChange(trendData) {
    if (!Array.isArray(trendData) || trendData.length < 2) return 0;

    trendData.sort(
        (a, b) => new Date(a.year, a.month - 1) - new Date(b.year, b.month - 1)
    );
    const latest = trendData[trendData.length - 1];
    let compareTo = trendData.find(
        (t) => t.month === latest.month && t.year === latest.year - 1
    );

    if (!compareTo) compareTo = trendData[0];
    if (!compareTo || compareTo.value <= 0) return 0;

    return ((latest.value - compareTo.value) / compareTo.value) * 100;
}

function renderCompetition(competition = 0) {
    const compValue = Math.round(competition * 100);
    let level = "High",
        color = "text-danger";
    if (compValue < 33) {
        level = "Low";
        color = "text-success";
    } else if (compValue < 66) {
        level = "Medium";
        color = "text-warning";
    }

    return `<span class="${color}">${compValue} (${level})</span>`;
}

function formatLabels(trendData) {
    return trendData.map((t) => {
        const d = new Date(t.year, t.month - 1);
        return d.toLocaleString("default", { month: "short", year: "2-digit" });
    });
}

function renderMiniChart(canvasId, labels, values) {
    const ctx = document.getElementById(canvasId)?.getContext("2d");
    if (!ctx) return null;

    if (chartInstances[canvasId]) {
        chartInstances[canvasId].destroy();
    }

    chartInstances[canvasId] = new Chart(ctx, {
        type: "line",
        data: {
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: "rgba(244, 163, 0, 0.3)",
                    borderColor: "#f4a300",
                    borderWidth: 1.5,
                    fill: true,
                    tension: 0.4,
                    pointRadius: 0,
                },
            ],
        },
        options: {
            responsive: false,
            plugins: {
                legend: { display: false },
                tooltip: { enabled: false },
            },
            scales: { x: { display: false }, y: { display: false } },
        },
    });
}

function renderFullChart(canvasId, labels, values, trendChange) {
    const ctx = document.getElementById(canvasId)?.getContext("2d");
    if (!ctx) return null;

    if (chartInstances[canvasId]) {
        chartInstances[canvasId].destroy();
    }

    const chart = new Chart(ctx, {
        type: "bar",
        data: {
            labels: labels,
            datasets: [
                {
                    label: "Search Volume",
                    data: values,
                    backgroundColor: "#f4a300",
                },
            ],
        },
        options: {
            responsive: true,
            layout: { padding: 10 },
            plugins: {
                legend: { display: false },
                tooltip: {
                    callbacks: {
                        label: (context) =>
                            `${context.parsed.y.toLocaleString()} searches`,
                    },
                },
                title: {
                    display: true,
                    text: `${trendChange.toFixed(0)}% in the last 12 months`,
                    font: { size: 16, weight: "bold" },
                    color: "#333",
                    padding: { top: 10, bottom: 20 },
                },
            },
            scales: {
                x: {
                    ticks: { color: "#555", font: { size: 12 } },
                    grid: { color: "#eee" },
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: (value) => {
                            if (value >= 1_000_000)
                                return (value / 1_000_000).toFixed(0) + "m";
                            if (value >= 1_000)
                                return (value / 1_000).toFixed(0) + "k";
                            return value;
                        },
                        color: "#555",
                        font: { size: 12 },
                    },
                    grid: { color: "#eee" },
                },
            },
        },
    });

    chartInstances[canvasId] = chart;
    return chart;
}
